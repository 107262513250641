<template>
	<div>
		<!-- <div class="title">
			<div class="flexX flexcenter">
				<div class="item"><span>当前余额:</span><span class="cdanger">{{common.money | tofixed}}</span></div>
				<div class="item"><span>今日订单提交金额:</span><span class="cdanger">{{common.order_add_gold | tofixed}}</span></div>
				<div class="item"><span>今日订单成功金额:</span><span class="cdanger">{{common.order_success_gold | tofixed}}</span></div>
				<div class="item"><span>今日代付提交金额:</span><span class="cdanger">{{common.pay_add_gold | tofixed}}</span></div>
				<div class="item"><span>今日代付成功金额:</span><span class="cdanger">{{common.pay_success_gold | tofixed}}</span></div>
			</div>
			<div class="flexX flexcenter mt20">				
				<div class="item"><span>冻结余额:</span><span class="cdanger">{{common.money_uncertain | tofixed}}</span></div>
				<div class="item"><span>今日订单提交笔数:</span><span class="cdanger">{{common.order_add_count}}</span></div>
				<div class="item"><span>今日订单成功笔数:</span><span class="cdanger">{{common.order_success_count}}</span></div>
				<div class="item"><span>今日代付提交笔数:</span><span class="cdanger">{{common.pay_add_count}}</span></div>
				<div class="item"><span>今日代付成功笔数:</span><span class="cdanger">{{common.pay_success_count}}</span></div>
			</div>
		</div> -->
		<div class="care">
			<div class="name">余额</div>
			<div class="care_list">
				<div>
					<div  class="title">当前余额</div>
					<div class="money">{{common.money | tofixed}} </div>
				</div>
				<!-- <div>
					<div  class="title">冻结余额</div>
					<div class="money">{{common.money_uncertain | tofixed}} </div>
				</div> -->
			</div>
		</div>
		<div class="care">
			<div class="name">代收</div>
			<div class="care_list">
				<div>
					<div  class="title">今日订单提交金额</div>
					<div class="money">{{common.order_add_gold | tofixed}} </div>
				</div>
				<div>
					<div  class="title">今日订单成功金额</div>
					<div class="money">{{common.order_success_gold | tofixed}} </div>
				</div>
				<div>
					<div  class="title">今日订单提交笔数</div>
					<div class="money">{{common.order_add_count}} </div>
				</div>
				<div>
					<div  class="title">今日订单成功笔数</div>
					<div class="money">{{common.order_success_count}} </div>
				</div>
			</div>
		</div>
		<div class="care">
			<div class="name">代付</div>
			<div class="care_list">
				<div>
					<div  class="title">今日代付提交金额</div>
					<div class="money">{{common.pay_add_gold | tofixed}} </div>
				</div>
				<div>
					<div  class="title">今日代付成功金额</div>
					<div class="money">{{common.pay_success_gold | tofixed}} </div>
				</div>
				<div>
					<div  class="title">今日代付提交笔数</div>
					<div class="money">{{common.pay_add_count}} </div>
				</div>
				<div>
					<div  class="title">今日代付成功笔数</div>
					<div class="money">{{common.pay_success_count}} </div>
				</div>
			</div>
		</div>
		
		<!-- <div class="care" v-for="(item,index) in dataList" :key="index">
			<div class="name">{{item.name}}</div>
			<div class="care_list">
				<template v-for="(itemSub ,index) in item.list">
					<div :key="index">
						<div  class="title">{{itemSub.name}}</div>
							<div class="money" v-if="itemSub.noTooltip">{{itemSub.value}} </div>
							
							<div v-else class="item" effect="dark" placement="top">
								<div class="money" v-for="(value, key, index) in itemSub.value" v-if="key == 'CNY'" :key="index">{{key}}:{{value}}<br /></div>
							</div>
						
					</div>
				</template>
			</div>
		</div> -->
	</div>
</template>
<script>
	export default {
		data() {
			return {
				common: {
					money:0,
					order_add_gold:0,
					order_success_gold:0,
					pay_add_gold:0,
					pay_success_gold:0,
					money_uncertain:0,
					order_add_count:0,
					order_success_count:0,
					pay_add_count:0,
					pay_success_count:0,
				},
				dataList:[
					{
						name:'余额',
						list:[
							{name:'当前余额',key:'money'},
							{name:'冻结余额',key:'money_uncertain'},
						]
					},
					{
						name:'订单',
						list:[
							{name:'今日订单提交金额',key:'order_add_gold'},
							{name:'今日订单成功金额',key:'order_success_gold'},
							{name:'今日订单提交笔数',noTooltip:true,key:'order_add_count'},
							{name:'今日订单成功笔数',noTooltip:true,key:'order_success_count'},
						]
					},
					{
						name:'代付',
						list:[
							{name:'今日代付提交金额',key:'pay_add_gold'},
							{name:'今日代付成功金额',key:'pay_success_gold'},
							{name:'今日代付提交笔数',noTooltip:true,key:'pay_add_count'},
							{name:'今日代付成功笔数',noTooltip:true,key:'pay_success_count'},
						]
					},
				]
			}
		},
		created() {
			this.commonTotal()
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			commonTotal() {
				this.$api.commonTotal({}).then(res => {
					console.log(res)
					if (res.status == 1) {
						this.common = res.data
						// this.dataList.forEach(item=>{
						// 	item.list.forEach(itemSub => {
						// 		this.$set(itemSub,'value',res.data[itemSub.key])
						// 	})
						// })
						
					}
				})
			}
		}
	}
</script>
<style scoped="scoped" lang="scss">
	@import '@/style/variables.scss';

	// .title {
	// 	background-color: $tableBorderColor;
	// 	padding: 20px;

	// 	.item {
	// 		flex: 1;
	// 	}
	// }
	.usdtCss{
	   color: red;
	   font-size: xx-small;
   	}
	.care{
		.name{
			position: relative;
			padding-left: 30px;
			margin: 20px 0px;
			&::after{
				content: "";
				position: absolute;
				width: 4px;
				height: 100%;
				top: 0px;
				left: 0px;
				background: #f0ad4e;
			}
		}
		.care_list{
			display: flex;
			>div{
				width: 400px;
				text-align: center;
				margin-right: 20px;
				.title{
					height: 70px;
					line-height: 70px;
					background:rgba($tableBorderColor, 0.5)
				}
				.money{
					height: 40px;
					background:  $tableBorderColor;
					display: flex;
					align-items: center;
					justify-content: center
				}
			}
		}
	}
</style>
